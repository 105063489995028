<template>  
  <v-container>
    <!-- <v-card elevation="0" class="d-flex mb-2 justify-center" style="border-radius: 24px 24px 8px 8px;;">
      <div class="pa-4 px-6">
        <v-card-title 
          class="pa-0 grey--text text--darken-3 font-weight-bold text-uppercase" 
          :style="$vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;'"
        >

          Planes de cliente registrados
        </v-card-title>
  
      </div>
    </v-card> -->

    <v-card elevation="0" class="d-flex mb-2"  style="border-radius: 24px 24px 8px 8px;;">
      <div class="pa-4 d-flex justify-space-between align-center" style="flex: 1; height: 64px;">

        <v-card-subtitle 
          class="pa-0" 
          :style="$vuetify.breakpoint.xs ? 'font-size: 16px;' : $vuetify.breakpoint.sm ? 'font-size: 20px;' : 'font-size: 24px;'"
        >
          <span class="grey--text text--darken-3 font-weight-bold">
            <span>
              Planes: 
            </span>
            <span class="grey--text text--darken-2 font-weight-bold">
              {{ (cliente ? `${cliente.num_cliente} - ${cliente.nombre} ${cliente.apellidos}` : 'Cargando...') }}
            </span>
          </span>
        </v-card-subtitle>

        <v-card-subtitle 
          class="pa-0" 
          :style="$vuetify.breakpoint.xs ? 'font-size: 16px;' : 'font-size: 18px;'"
        >
          <router-link id="linkReturnClientes" color="primary" to="/clientes" class=" font-weight-bold">
            <v-icon color="primary" :size="20">mdi-arrow-left-bold</v-icon>
            Regresar a Clientes
          </router-link>
        </v-card-subtitle>


      </div>
        <!-- <v-divider class="my-6"></v-divider> -->
    </v-card>
    

    <!-- <div v-show="false">{{ plazo_calculado }}</div> -->
    <!-- <div v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" class="text-center title mb-2">
      {{ (cliente ? cliente.nombre + ' ' + cliente.apellidos : 'Cargando...') }}
    </div> -->
    <DataTable
      id="plancliente-table"
      :title="(cliente ? cliente.nombre + ' ' + cliente.apellidos : 'Cargando...')"
      :subtitle="`Planes registrados`"
      :headers="headers"
      :items="planesTable.items"
      :loading="tableLoading"
      :searchEnabled="true"
      :toolbarActions="true"
      :toolbarTitle="false"
      tooltipEditarText="Editar póliza"
      extraEliminarInfoText="La póliza asociada al número anterior se eliminará permanentemente y no podrá ser recuperada."
      showItemDescrToDelete="Póliza:"
      showItemPropToDelete="poliza"
      @editItem="planesTable.editItem"
      @deleteItem="planClienteDelete"
    >
      <template slot="toolbarActions">
        <!-- Boton oculto con evento clic accionado de manera programática con ayuda de la etiqueta "ref" -->
        <button v-if="!tableLoading" v-show="false" type="button" @click="showPlanClienteOnEnter(itemFromContratoVigenciaView)" ref="edit_item">Click Me!</button>
        
        <!-- <Button text :elevation="2" class="mr-2" to="/clientes">
          <v-icon left>mdi-reply</v-icon>
          Regresar
        </Button> -->

          <Dialog
            useToolbarStyle
            v-model="planesTable.dialog"
            btnText="Agregar  Plan/Póliza"
            :title="`${(!$vuetify.breakpoint.xs) ? `${planesTable.formTitle} plan de cliente` : ''}`"
            :subtitle="`
              ${(!$vuetify.breakpoint.xs) ? truncateStr((cliente ? cliente.num_cliente + ' - ' + cliente.nombre + ' ' + cliente.apellidos : 'Cargando...'), 50) : ''}
            `"
            :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            :no-click-animation="false"
            persistent
            @onCloseToolbarBtn="planesTable.dialog = false"
          >
            <template slot="content">
              <v-card-text>

              <v-row>
                <v-col cols="12" class="mt-n8">

                  <div v-if="$vuetify.breakpoint.xs" class="text-center" style="font-size: 16px;">
                    <span class="font-weight-bold"> 
                      Editar plan de cliente
                    </span>
                    <div style="font-size: 14px;">
                      {{ cliente.nombre + ' ' + cliente.apellidos }}
                    </div>
                  </div>
                  <v-divider v-if="$vuetify.breakpoint.xs" class="my-1"></v-divider>
                </v-col> 
              </v-row>

              <!-- Para que un ref tome efecto el nodo debe existir en el DOM (tambien se toma si esta oculto), 
              en este caso los componentes dentro de Dialog no existen hasta que la accion planesTable.dialog 
              es true -->
              <v-form ref="form" v-model="validFormPlanes" >

                <v-row>
                  <v-col cols="12" sm="6" md="4"> 
                    <VTextField
                      label="No. póliza"
                      v-model="planesTable.editedItem.poliza"
                      :rules="[FormValidations.required, FormValidations.onlyInteger]"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja /*|| planesTable.editedItem.plan_iniciado*/"
                    ></VTextField>
                  </v-col> 

                  <v-col cols="12" sm="6" md="8">
                    <v-select
                      label="Nombre del plan"
                      :value="planesTable.editedItem.id_plan"
                      @change="handleChangePlan"
                      :rules="[FormValidations.required]"
                      :items="planesSelect"
                      :disabled="planesTable.isEditable"
                    ></v-select>
                  </v-col> 

                  <v-col cols="12" sm="6" md="4">
                    <VTextField
                      v-tooltip="tooltip_trimestres_al_anio"
                      label="Duración (años)"
                      v-model="planesTable.editedItem.anios_aseguradora"
                      :rules="[
                        FormValidations.required,
                        FormValidations.onlyInteger,
                        FormValidations.nonZero,
                        FormValidations.planIsSelected(planSelected),
                        v => FormValidations.planValidYearRange(v, planSelected),
                      ]"
                      :maxlength="2"
                      :disabled="planesTable.isEditable"
                    ></VTextField>
                  </v-col> 

                  <v-col cols="12" sm="6" md="4">
                    <VCurrencyField
                      :label="`Prima neta`"
                      v-model="planesTable.editedItem.prima_neta"
                      :rules="[FormValidations.required]"
                      prefix="$"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || trimestres > 0 /*|| planesTable.editedItem.plan_iniciado*/"
                    ></VCurrencyField>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <VCurrencyField
                      :label="`Pago fraccionado`"
                      v-model="planesTable.editedItem.pago_fraccionado"
                      :rules="[FormValidations.required]"
                      prefix="$"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || trimestres > 0 /*|| planesTable.editedItem.plan_iniciado*/"
                    ></VCurrencyField>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <VCurrencyField
                      :label="`Gastos de expedición`"
                      v-model="planesTable.editedItem.gastos_expedicion"
                      :rules="[FormValidations.required]"
                      prefix="$"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || trimestres > 0 /*|| planesTable.editedItem.plan_iniciado*/"
                    ></VCurrencyField>
                  </v-col>
  
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      label="Moneda"
                      v-model="planesTable.editedItem.moneda"
                      :rules="[FormValidations.required]"
                      :items="PLAN_DATA.MONEDAS_SELECT"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_iniciado"
                    ></v-select>
                  </v-col> 

                  <v-col v-show="planesTable.editedItem.moneda && planesTable.editedItem.moneda != MONEDAS.MXN" cols="12" sm="6" md="4">
                    <VTextField
                      :label="`Tipo de cambio ${planesTable.editedItem.moneda || ''} a MN`"
                      :rules="[FormValidations.required, FormValidations.onlyRealNumber, FormValidations.nonZero]"
                      v-model="planesTable.editedItem.tipo_cambio"
                      :maxlength="tipoCambioMaxLength(planesTable.editedItem.moneda, planesTable.editedItem.tipo_cambio)"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_iniciado"
                    ></VTextField>
                  </v-col>
  
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      label="Forma de pago"
                      ref="forma_pago"
                      v-model="planesTable.editedItem.forma_pago"
                      :rules="[FormValidations.required]"
                      :items="FORMA_PAGO_SELECT"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_completado"
                    ></v-select>
                  </v-col> 

                  <v-col cols="12" sm="6" md="4">
                    <VTextField
                      ref="pago_periodico"
                      :label="`Pago periódico (MN)`"
                      :value="planesTable.editedItem.pago_periodico_anio"
                      @input="planesTable.editedItem.pago_periodico_anio = $event"
                      prefix="$"
                      persistent-hint
                      :hint="`Referencia original: ${formatCurrency(plazo_calculado)}`"
                      :rules="[
                        FormValidations.required,
                        FormValidations.onlyRealNumber,
                        FormValidations.nonZero,
                      ]"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_completado"
                    ></VTextField>
                  </v-col>

                  <!-- +planesTable.editedItem.anios_aseguradora > 1 -->
                  <!-- <v-col v-if="false" cols="12" sm="6" md="4">
                    <VCurrencyField
                      :label="`Pago periódico (restantes, MN)`"
                      :value="planesTable.isEditable 
                        ? planesTable.editedItem.pago_periodico_resto 
                        : plazo_calculado_resto
                      "
                      @input="planesTable.editedItem.pago_periodico_resto = $event"
                      prefix="$"
                      :hint="`Referencia original: ${formatCurrency(plazo_calculado_resto)}`"
                      :disabled="planesTable.editedItem.plan_iniciado"
                      persistent-hint
                    ></VCurrencyField>
                  </v-col> -->

                  <!-- <v-col v-if="false" cols="12" sm="6" md="4">
                    <VCurrencyField
                      :label="`Total, 1er. año ${(planesTable.editedItem.moneda && planesTable.editedItem.moneda != MONEDAS.MXN || '') && `(${planesTable.editedItem.moneda})` }`"
                      :value="asegTotal"
                      prefix="$"
                      disabled
                    ></VCurrencyField>
                  </v-col> -->

                  <!-- <v-col v-if="false" cols="12" sm="6" md="4">
                    <VCurrencyField
                      :label="`Total, ${(planesTable.editedItem.anios_aseguradora-1) <= 1 ? 'año restante' : 'años restantes ' + `(${(planesTable.editedItem.anios_aseguradora-1)})` } ${(planesTable.editedItem.moneda && planesTable.editedItem.moneda != MONEDAS.MXN || '') && `(${planesTable.editedItem.moneda})` }`"
                      :value="asegTotalResto"
                      prefix="$"
                      disabled
                    ></VCurrencyField>
                  </v-col> -->

                  <!-- <v-col cols="12" sm="6" md="4"> 
                    <VCurrencyField
                      :label="`Al trimestre, 1er. año ${(planesTable.editedItem.moneda && planesTable.editedItem.moneda != MONEDAS.MXN || '') && '(MXN)'}`"
                      :value="calcular_trimestre"
                      prefix="$"
                      disabled
                    ></VCurrencyField>
                  </v-col> 

                  <v-col cols="12" sm="6" md="4"> 
                    <VCurrencyField
                      :label="`${a ? a + ', 1er. año' : ''} ${(planesTable.editedItem.moneda && planesTable.editedItem.moneda != MONEDAS.MXN || '') && '(MXN)'}`"
                      :value="plazo_calculado"
                      prefix="$"
                      disabled
                    ></VCurrencyField>
                  </v-col> 

                  <v-col v-if="asegTotalResto" cols="12" sm="6" md="4"> 
                    <VCurrencyField
                      :label="`Al trimestre, ${(planesTable.editedItem.anios_aseguradora-1) <= 1 ? 'año restante' : 'años restantes ' + `(${(planesTable.editedItem.anios_aseguradora-1)})` } ${(planesTable.editedItem.moneda && planesTable.editedItem.moneda != MONEDAS.MXN || '') && '(MXN)'}`"
                      :value="calcular_trimestre_resto"
                      prefix="$"
                      disabled
                    ></VCurrencyField>
                  </v-col> 

                  <v-col v-if="asegTotalResto" cols="12" sm="6" md="4"> 
                    <VCurrencyField
                      :label="`${a || ''}, ${(planesTable.editedItem.anios_aseguradora-1) <= 1 ? 'año restante' : 'años restantes ' + `(${(planesTable.editedItem.anios_aseguradora-1)})` } ${(planesTable.editedItem.moneda && planesTable.editedItem.moneda != MONEDAS.MXN || '') && '(MXN)'}`"
                      :value="plazo_calculado_resto"
                      prefix="$"
                      disabled
                    ></VCurrencyField>
                  </v-col> -->

                  <v-col cols="12" sm="6" md="4"> 
                    <v-menu
                      v-model="dateInit.dateOpen"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <VTextField
                          :value="dateInit.formattedDate"
                          :hint="dateInit.dateText && $dayjs(dateInit.dateText).format('dddd, DD [de] MMM. [del] YYYY')"
                          :label="'Fecha de pago inicial'"
                          :rules="[FormValidations.required]"
                          prepend-inner-icon="mdi-calendar"
                          persistent-hint
                          :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_iniciado"
                          v-on="on"
                        ></VTextField>
                      </template>

                      <v-date-picker 
                        v-model="dateInit.dateText" 
                        @change="dateInit.dateOpen = false" 
                        :allowed-dates="allowedDates"
                        no-title 
                        scrollable>
                      </v-date-picker>
                    </v-menu>
                  </v-col> 

                  <v-col v-show="planesTable.editedItem.forma_pago != null && planesTable.editedItem.forma_pago == FORMA_PAGO.SEMANAL" cols="12" sm="6" md="4">
                    <v-select
                      label="Día de pago"
                      ref="dia_pago"
                      v-model="planesTable.editedItem.dia_pago"
                      :rules="[planesTable.editedItem.forma_pago == FORMA_PAGO.SEMANAL ? FormValidations.required : true]"
                      :items="PLAN_DATA.DIAS_SEMANA_SELECT"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_completado"
                    ></v-select>
                  </v-col>   

                  <v-col v-show="planesTable.editedItem.forma_pago != null && planesTable.editedItem.forma_pago == FORMA_PAGO.MENSUAL" cols="12" sm="6" md="4">
                    <v-select
                      label="Número de día de pago"
                      ref="num_dia_pago"
                      v-model="planesTable.editedItem.num_dia_pago"
                      :items="PLAN_DATA.MAX_NUM_DIAS_AL_MES"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_completado"
                    ></v-select>
                  </v-col>   

                  <v-col 
                    v-show="planesTable.isEditable && planesTable.editedItem.plan_iniciado"
                    cols="12" sm="6" md="4"
                  > 
                    <v-menu
                      v-model="dateProxPago.dateOpen"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <VTextField
                          :value="dateProxPago.formattedDate"
                          :hint="dateProxPago.dateText && $dayjs(dateProxPago.dateText).format('dddd, DD [de] MMM. [del] YYYY')"
                          :label="'Fecha próxima de pago'"
                          :rules="[FormValidations.required]"
                          prepend-inner-icon="mdi-calendar"
                          persistent-hint
                          :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_completado"
                          v-on="on"
                        ></VTextField>
                      </template>

                      <v-date-picker 
                        v-model="dateProxPago.dateText" 
                        @change="dateProxPago.dateOpen = false" 
                        :allowed-dates="allowedDates"
                        no-title 
                        scrollable>
                      </v-date-picker>
                    </v-menu>
                  </v-col> 

                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      label="Turno de pago"
                      v-model="planesTable.editedItem.turno_pago"
                      :rules="[FormValidations.required]"
                      :items="PLAN_DATA.TURNO_PAGO_SELECT"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_completado"
                    ></v-select>
                  </v-col>

                  <!-- <v-col v-show="planesTable.editedItem.suspendido && planesTable.editedItem.plan_iniciado" cols="12" sm="6" md="4"> 
                    <VTextField
                      :value="planesTable.editedItem.fecha_prox_pago && $dayjs(planesTable.editedItem.fecha_prox_pago).format('DD/MM/YYYY')"
                      :hint="planesTable.editedItem.fecha_prox_pago && $dayjs(planesTable.editedItem.fecha_prox_pago).format('dddd, DD [de] MMM. [del] YYYY')"
                      placeholder="- - -"
                      label="Fecha próxima de pago"
                      prepend-inner-icon="mdi-calendar"
                      persistent-hint
                    ></VTextField>
                  </v-col> -->

                  <!-- <v-col v-if="false" cols="12" sm="6" md="4"> 
                    <VTextField
                      :value="fechaProxPago && $dayjs(fechaProxPago).format('DD/MM/YYYY')"
                      :hint="fechaProxPago && $dayjs(fechaProxPago).format('dddd, DD [de] MMM. [del] YYYY')"
                      placeholder="- - -"
                      :label="'Fecha próxima de pago'"
                      prepend-inner-icon="mdi-calendar"
                      persistent-hint
                      disabled
                    ></VTextField>
                  </v-col> -->

                  <v-col cols="12" sm="6" md="4"> 
                    <v-menu
                      v-model="dateInitContrato.dateOpen"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <VTextField
                          :value="dateInitContrato.formattedDate"
                          :hint="dateInitContrato.dateText && $dayjs(dateInitContrato.dateText).format('dddd, DD [de] MMM. [del] YYYY')"
                          :label="'Fecha inicio contrato'"
                          prepend-inner-icon="mdi-calendar"
                          persistent-hint
                          :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_completado"
                          v-on="on"
                        ></VTextField>
                      </template>

                      <v-date-picker 
                        v-model="dateInitContrato.dateText" 
                        @change="dateInitContrato.dateOpen = false" 
                        no-title 
                        scrollable>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" sm="6" md="4"> 
                    <v-menu
                      v-model="dateEndContrato.dateOpen"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <VTextField
                          :value="dateEndContrato.formattedDate"
                          :hint="dateEndContrato.dateText && $dayjs(dateEndContrato.dateText).format('dddd, DD [de] MMM. [del] YYYY')"
                          :label="'Fecha fin contrato'"
                          prepend-inner-icon="mdi-calendar"
                          :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_completado"
                          persistent-hint
                          v-on="on"
                        ></VTextField>
                      </template>

                      <v-date-picker 
                        v-model="dateEndContrato.dateText" 
                        @change="dateEndContrato.dateOpen = false" 
                        no-title 
                        scrollable>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- :disabled="planesTable.editedItem.plan_iniciado" -->
                  <v-col cols="12" sm="12" md="4" class="pt-0 mt-n1"> 
                    <v-checkbox
                      v-model="planesTable.editedItem.domiciliado"
                      color="primary"
                      :label="`Pago domiciliado?`"
                      :disabled="valorInicialEstatusSuspension || valorInicialEstatusBaja || planesTable.editedItem.plan_completado"
                      :hint="''"
                      persistent-hint
                    ></v-checkbox>
                  </v-col>

                </v-row>
              </v-form>

<!-- ================== -->
<!-- TRIMESTRES PAGADOS -->
<!-- ================== -->
                <v-row>
                  <v-col cols="12" class="mt-1 mb-n4" v-if="!planesTable.editedItem.plan_completado">
                    Establecer manualmente los trimestres, abonos, saldo acreedor, saldo deudor, 
                    etc. para clientes existentes, o ver el plan de pagos relacionado.
                  </v-col>
                  <v-col v-else cols="12" class="py-0 pt-6">
                    <v-chip
                      color="grey lighten-4"
                      label
                    >
                      <div class="green--text text-h5 font-weight-bold text--darken-3">
                        PLAN COMPLETADO ✔
                      </div>
                    </v-chip>
                  </v-col>

                  <!-- BOTON "ESTABLECER ABONOS" -->
                  <v-col cols="12" sm="6" md="4" v-show="true /*!planesTable.editedItem.plan_completado*/">
                    <Dialog
                      useToolbarStyle
                      persistent
                      overlay-opacity="0.7"
                      v-model="trimestresDialog"
                      :btnText="`${planesTable.editedItem.plan_completado ? 'Ver' : 'Establecer'} abonos`"
                      :title="`${(!$vuetify.breakpoint.xs) ? `Abonos por ${plazoToString(planesTable.editedItem.forma_pago).lower}` : ''}`"
                      :subtitle="`
                        ${(!$vuetify.breakpoint.xs) ? truncateStr(cliente.nombre + ' ' + cliente.apellidos, 50) : ''}
                      `"
                      :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                      :disabled="
                        !(planesTable.editedItem.anios_aseguradora >= planSelected.min_anios 
                        && planesTable.editedItem.anios_aseguradora <= (planSelected.max_anios || Infinity)
                        && planesTable.editedItem.prima_neta
                        && planesTable.editedItem.gastos_expedicion
                        && planesTable.editedItem.moneda
                        && planesTable.editedItem.tipo_cambio)
                      "
                      :showCloseIcon="true"
                      :no-click-animation="false"
                      max-width="1024px"
                      @onCloseToolbarBtn="handleMigracionCancel"
                    >
                      <template slot="content">
                        <v-card-text>
    <!-- CAMPOS GENERALES Y DEL TRIMESTRE ACTUAL -->
                          <v-row>
                            <v-col cols="12" class="mt-n4">

                              <div v-if="$vuetify.breakpoint.xs" class="text-center" style="font-size: 16px;">
                                <span class="font-weight-bold"> 
                                  Abonos por trimestre 
                                  {{ ` (a ${planesTable.editedItem.anios_aseguradora} año${+planesTable.editedItem.anios_aseguradora > 1 ? 's' : ''})` }}
                                </span>
                                <div style="font-size: 14px;">
                                  {{ cliente.nombre + ' ' + cliente.apellidos }}
                                </div>
                              </div>
                              <v-divider v-if="$vuetify.breakpoint.xs" class="my-1"></v-divider>

                              <div class="d-flex justify-space-between">
                                <div>
                                  <div>
                                    <span class="font-weight-bold">Póliza: </span>
                                    {{ `${planesTable.editedItem.poliza} | ${planesTable.editedItem.forma_pago}` }} {{ planesTable.editedItem.dia_pago && `(${formatDiaPago(planesTable.editedItem.dia_pago)})` }}
                                  </div>
                                  <div>
                                    <span class="font-weight-bold">Moneda: </span>
                                    {{ `${planesTable.editedItem.moneda} (${formatCurrency(planesTable.editedItem.aseguradora_total)})` }}
                                  </div>
                                  <div>
                                    <span class="font-weight-bold">Pago periódico: </span>
                                    {{ `${formatCurrency(planesTable.editedItem.pago_periodico_anio)}` }}
                                  </div>
                                </div>

                                <div>
                                  <div>
                                    <span class="font-weight-bold">Prima neta: </span>
                                    {{ `${formatCurrency(planesTable.editedItem.prima_neta)}` }}
                                  </div>
                                  <div>
                                    <span class="font-weight-bold">Pago fracc: </span>
                                    {{ `${formatCurrency(planesTable.editedItem.pago_fraccionado)}` }}
                                  </div>
                                  <div>
                                    <span class="font-weight-bold">Gastos exp: </span>
                                    {{ `${formatCurrency(planesTable.editedItem.gastos_expedicion)}` }}
                                  </div>
                                </div>
                              </div>

                            </v-col>
                          </v-row>

                          <v-divider class="mt-2 mb-0"></v-divider>

                        <v-form ref="formTrimestres" v-model="validFormTrimestresPagados" class="mt-6">

                          <v-row>
                            <v-col cols="12" sm="6" md="3">
                              <VTextField
                                :label="`Núm. ${plazoToString(planesTable.editedItem.forma_pago).lower}s pagados`"
                                :maxlength="2"
                                :value="trimestres"
                                @input="handleInputTrimestres"
                              ></VTextField>
                            </v-col>

                            <v-col cols="12" sm="6" md="3" v-show="planesTable.editedItem.moneda != MONEDAS.MXN && !planesTable.editedItem.plan_completado">
                              <VTextField
                                :label="`Tipo de cambio (${plazoToString(planesTable.editedItem.forma_pago).lower} ${+trimestres + 1})`"
                                :value="tipo_cambio_migr"
                                @input="handleInputTipoCambioMigr"
                                :maxlength="tipoCambioMaxLength(planesTable.editedItem.moneda, tipo_cambio_migr)"
                                :rules="[FormValidations.onlyRealNumber, FormValidations.nonZero, FormValidations.required]"
                                prefix="$"
                              ></VTextField>
                            </v-col>
  
                            <v-col 
                              v-show="!['Anual', 'Semestral', 'Trimestral'].includes(planesTable.editedItem.forma_pago) && !planesTable.editedItem.plan_completado"
                              cols="12" sm="6" md="3"
                            > 
                              <VCurrencyField
                                :label="`Recibido a cuenta (${plazoToString(planesTable.editedItem.forma_pago).lower} ${+trimestres + 1})`"
                                v-model="planesTable.editedItem.recibido_a_cuenta"
                              ></VCurrencyField>
                            </v-col>

                            <v-col 
                              v-show="!['Anual', 'Semestral', 'Trimestral'].includes(planesTable.editedItem.forma_pago) && !planesTable.editedItem.plan_completado" 
                              cols="12" sm="6" md="3"
                            > 
                              <VTextField
                                :label="`Abonos aplicados (${plazoToString(planesTable.editedItem.forma_pago).lower} ${+trimestres + 1})`"
                                :value="abonos_pagados"
                                @input="handleInputAbonosPagados"
                                prefix=""
                                :hint="''"
                                persistent-hint
                                :disabled="false /*+planesTable.editedItem.recibido_a_cuenta == 0*/"
                                :maxlength="2"
                              ></VTextField>
                            </v-col>

                            <v-col 
                              cols="12" sm="6" md="3"
                            > 
                              <VCurrencyField
                                :label="`Saldo acreedor`"
                                v-model="planesTable.editedItem.saldo_a_favor"
                              ></VCurrencyField>
                            </v-col>

                            <!-- <v-col 
                              cols="12" sm="6" md="3"
                            > 
                              <VCurrencyField
                                :label="`Saldo deudor`"
                                v-model="planesTable.editedItem.saldo_en_contra"
                              ></VCurrencyField>
                            </v-col> -->
                          </v-row>

    <!-- ITERACION DE LOS TRIMESTRES PAGADOS -->
                          <v-row v-for="(item, index) in trimestresFields" :key="`trim_${index}`" class="mb-4">
                            <v-row v-show="
                              !planesTable.editedItem.plan_completado 
                                ? (trimestresFields.length - 1 > index) 
                                : true
                            ">
                              <v-col cols="12" :class="index == 0 ? 'mt-3 mb-n5' : 'mt-5 mb-n5'"> 
                                <div>
                                  <v-chip
                                    label
                                    style="bottom: -1px"
                                  >
                                    <div v-if="IS_DEV_USER && trimestresFieldsAll[index]" class="font-weight-bold pr-2"> 
                                      {{ `Id ${trimestresFieldsAll[index].id} |` }}
                                    </div>
                                    {{ plazoToString(planesTable.editedItem.forma_pago).capitalized }} {{ index + 1 }}
                                  </v-chip>
                                </div>
  
                                <v-divider/>
                              </v-col>
  
                              <v-col cols="12" sm="6" md="4"> 
                                <VTextField
                                  :label="`Tipo de cambio (${planesTable.editedItem.moneda || ''} a MN)`"
                                  :value="item.tipo_cambio"
                                  @input="handleTipoCambio($event, index, (+$event * pagoMonedaContratada(index)) )"
                                  :rules="[FormValidations.onlyRealNumber, FormValidations.nonZero, FormValidations.required]"
                                  :hint="`
                                    ${formatCurrency(pagoMonedaContratada(index), {prefix: ''})} x ${item.tipo_cambio} 
                                    = ${formatCurrency( +item.tipo_cambio * pagoMonedaContratada(index), {prefix: ''})} 
                                      ${(index > 0 ? +trimestresFields[index-1].saldo : 0) < 0 ? '' : '+'}${(index > 0 ? formatCurrency(+trimestresFields[index-1].saldo, {prefix: ''}) : 0)}
                                  `"
                                  :disabled="planesTable.editedItem.moneda == MONEDAS.MXN"
                                  :maxlength="tipoCambioMaxLength(planesTable.editedItem.moneda, item.tipo_cambio)"
                                  persistent-hint
                                ></VTextField>
                              </v-col>
  
                              <v-col cols="12" sm="6" md="4"> 
                                <VCurrencyField
                                  :label="`Abonado`"
                                  :value="item.abonado"
                                  @input="handleAbonado($event, index, (+item.tipo_cambio * pagoMonedaContratada(index)) )"
                                ></VCurrencyField>
                              </v-col>
  
                              <v-col cols="12" sm="6" md="4" class="d-flex justify-start align-center" :style="`font-size: 16px;  padding-top: 0px; padding-bottom: ${(!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm) ? '28' : '12'}px`"> 
                                <div>
                                  <div class="font-weight-bold" style="font-size: 12px;">
                                    Saldo restante:
                                  </div>
                                  <div :class="+item.saldo > 0 ? 'red--text text--darken-4' : 'green--text text--darken-4' ">
                                    {{ formatCurrency(item.saldo) }}
                                  </div>
                                </div>
                                <!-- <VCurrencyField
                                  :label="`Saldo`"
                                  v-model="item.saldo"
                                  allowNegative
                                  readonly
                                ></VCurrencyField> -->
                              </v-col>
                            </v-row>
                          </v-row>
                        </v-form>

                        </v-card-text>
                      </template>

                      <template slot="actions">
                        <!-- <Button 
                          text 
                          @click="handleMigracionCancel"
                        >
                          Cancelar
                        </Button> -->

                        <Button 
                          v-if="!planesTable.editedItem.plan_completado
                          && !Boolean(valorInicialEstatusSuspension)
                          && !Boolean(valorInicialEstatusBaja)"
                          v-tooltip.left="$vuetify.breakpoint.xs ? '' : 'Guarda los cambios de <br />esta ventana solamente'"
                          text 
                          @click="handleMigracionSave"
                          :disabled="!validFormTrimestresPagados" 
                        >
                          Guardar cambios
                        </Button>
                      </template>
                    </Dialog> 

                  </v-col>

                  <v-col v-if="planesTable.isEditable" cols="12" sm="6" md="6">
                    <Button
                      color="#efefef"
                      v-tooltip="'Ir al detalle del plan de pago <br /> para este plan de cliente'"
                      @click="goToClientePlanPago"
                    >
                      Plan de pagos
                    </Button>
                  </v-col>

                </v-row>
<!-- ================== -->
<!-- TRIMESTRES PAGADOS -->
<!-- ================== -->


                <v-row>
                  <v-col 
                    v-if="planesTable.isEditable && !planesTable.editedItem.plan_completado" 
                    class="pb-0"
                    cols="12" sm="6" md="6"
                  > 
                    <v-switch
                      v-model="planesTable.editedItem.suspendido"
                      color="amber darken-3"
                      :disabled="planesTable.isEditable && planesTable.editedItem.estatus"
                      :prepend-icon="`${planesTable.editedItem.suspendido ? 'mdi-account-clock' : 'mdi-account-check'}`"
                      :label="`${planesTable.editedItem.suspendido ? 'Estatus: suspendido' : 'Estatus: no suspendido'}`"
                    />
                  </v-col>

                  <v-col 
                    v-if="planesTable.isEditable && !planesTable.editedItem.plan_completado"
                    class="pb-0"
                    cols="6" sm="6" md="6"
                  > 
                    <v-switch
                      v-model="planesTable.editedItem.estatus"
                      color="red darken-3"
                      :disabled="planesTable.isEditable && !planesTable.editedItem.suspendido"
                      :prepend-icon="`${planesTable.editedItem.estatus ? 'mdi-package-down' : 'mdi-package-up'}`"
                      :label="`${planesTable.editedItem.estatus ? 'Estatus: baja' : 'Estatus: alta'}`"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
    
            <template slot="actions">
              <!-- <Button text @click="planesTable.close">Cancelar</Button> -->
              <Button 
                v-if="!planesTable.editedItem.plan_completado"
                text 
                :loading="tableLoading"
                :disabled="!validFormPlanes"
                @click="planClienteSave"
              >
                Guardar Todo
              </Button>
            </template>
          </Dialog> 
        
      </template>
    </DataTable>


    <Dialog
      useToolbarStyle
      v-model="showSuspendDeactivationDialog"
      overlay-opacity="0.7"
      max-width="1024px"
      :btnText="``"
      :title="`Está a punto de quitar la suspensión al plan de cliente`"
      :subtitle="``"
      :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      @onCloseToolbarBtn="handleOnCloseSuspendDeactivationDialog"
      @onClickOutside="handleOnCloseSuspendDeactivationDialog"
    >
      <template slot="content">
        <v-card-title class="justify-center">
          <span class="headline">
            Se quitará el estatus de suspensión al siguiente registro:
          </span>
        </v-card-title>

        <div class="mb-4 font-weight-bold" style="text-align: center; font-size: 24px;">
          {{ `Póliza: ${planesTable.editedItem.poliza}` }}
        </div>

        <div class="ml-4">

          <div class="grey--text text--darken-3 mt-6" style="font-size: 17px; vertical-align: bottom;">
            Este plan de cliente relacionado a <b>{{`${cliente.nombre} ${cliente.apellidos}`}}</b> se actualizará al momento de 
            dar clic en el botón "<b>ACEPTAR</b>" de esta ventana.
          </div>

          <div class="grey--text text--darken-3 mt-6" style="font-size: 17px; vertical-align: bottom;">
            El proceso actualizará el plan de cliente junto con el plan de pagos y aplicará
            automáticamente la cantidad de abonos en 0 con respecto a la fecha en la que se suspendió este plan, insertando
            el último abono en la fecha más próxima a la actual y dejando la proxima fecha de pago en un dia posterior 
            dependiendo de la forma de pago que se trate.
          </div>

          <v-form ref="form_suspension" v-model="validFormSuspension" class="my-6">
            <v-row class="justify-center">
              <v-col v-if="planesTable.editedItem.moneda && planesTable.editedItem.moneda != MONEDAS.MXN" cols="12" sm="6" md="6">
                <VTextField
                  :label="`Proporcione un nuevo tipo de cambio (${planesTable.editedItem.moneda || ''} a MN)`"
                  :rules="[FormValidations.required, FormValidations.onlyRealNumber, FormValidations.nonZero]"
                  prefix="$"
                  v-model="nuevoTipoCambioOnDesSuspension"
                  :hint="`Tipo de cambio a la fecha de hoy: ${$dayjs().format('dddd, DD [de] MMM. [del] YYYY')}`"
                  :maxlength="tipoCambioMaxLength(planesTable.editedItem.moneda, nuevoTipoCambioOnDesSuspension)"
                  persistent-hint
                ></VTextField>
              </v-col>
            </v-row>
          </v-form>

          <!-- <div class="warning--text text--darken-1 mt-6 mb-n7 font-weight-bold text-center" style="font-size: 17px; vertical-align: bottom;">
            --- IMPORTANTE LEER LO SIGUIENTE ---
          </div>

          <div class="grey--text text--darken-3 mt-6" style="font-size: 17px; vertical-align: bottom;">
            <v-alert
              border="left"
              colored-border
              color="warning"
              class=" mb-0"
            >
              <div class=" grey--text text--darken-3" style="font-size: 17px; vertical-align: bottom;">
                Una vez realizado el proceso, esta ventana se cerrará y se dejará la ventana anterior del plan de cliente con la información actualizada.
                Si al revisar la información hubiera algún dato que se necesite cambiar, como por ejemplo cambiar la fecha de próximo pago o algún otro 
                dato editable, se puede hacer de una vez y posteriormente dar clic en el botón "<b>GUARDAR TODO</b>" para que se tomen los cambios.
                
                <div class="mt-4">
                  <b>PERO</b> si no se requiere hacer cambios, entonces se puede cerrar el plan del cliente haciendo clic en el botón "<b>X</b>". 
                  Igualmente se puede dar clic en "<b>GUARDAR TODO</b>" cuando no se ha hecho ninguna modificación, pero es un paso innecesario 
                  ya que el sistema procesará los datos nuevamente sin cambiar nada.
                </div>
              </div>
            </v-alert>
          </div> -->

        </div>
      </template>

      <template slot="actions">
        <!-- <Button text @click="handleOnCloseSuspendDeactivationDialog">
          Cancelar
        </Button> -->
          
        <Button text @click="planClienteUpdateOnSuspendDeactivation" :disabled="!validFormSuspension">
          Aceptar
        </Button>
      </template>
    </Dialog> 

    <vue-element-loading 
      :active="fullPageLoading" 
      :text="vueElementLoadingText" 
      :text-style="{ color: '#333', fontSize: '20px' }" 
      :is-full-screen="true"
    />

  </v-container>

</template>

<script>
import Vue from "vue";
import Button from "@/components/ui/Button";
import Dialog from "@/components/ui/Dialog";
import DataTable from "@/components/ui/DataTable";
import VCurrencyField from "@/components/ui/VCurrencyField";
import useDataTable from "@/composables/useDataTable";
import useDatepicker from "@/composables/useDatepicker";

import { APP_DEBUG_MODE as APP_DEBUG, PLAN_DATA, MONEDAS, FORMA_PAGO, DIAS_SEMANA } from "@/utils/Constants";
import {
  sortById,
  currency, 
  isObj,
  calcularPlazo, 
  formatCurrency, 
  normalizeStr, 
  calcularNumPagosAlTrimestre,
  plazoToString,
  esFormaPagoNoTrimestral,
  tipoCambioMaxLength,
  truncateStr,
  formatDiaPago,
  btoaDecodeData,
  btoaEncodeData,
  activateFullPageLoading,
  disableFullPageLoading,
} from "@/utils/Functions";
import { getCurrentInstance, reactive, ref, computed, watch, onUpdated, onMounted, onBeforeUnmount } from "vue";
import FormValidations from "@/utils/FormValidations";
import { state, actions, getters, STORE_NAMESPACES } from "@/store";

export default {
  components: {
    Button,
    Dialog,
    DataTable,
    VCurrencyField,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { APP, CLIENTES, PLANES, PLANES_CLIENTE, PLAN_PAGOS, USERS } = STORE_NAMESPACES;
        
    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const APP_DEBUG_MODE = APP_DEBUG || IS_DEV_USER;

    onBeforeUnmount(() => {
      APP_DEBUG_MODE && console.log("[PlanesClientes]: PlanesCliente + PlanPagos unmount => SET_DEFAULT()");

      actions[PLANES_CLIENTE].SET_DEFAULT();
      actions[PLAN_PAGOS].SET_DEFAULT();
    });

    const isNewClient = vm.proxy.$root.$route.query.isNewClient; // query desde el apartado de cliente (al registrar uno nuevo)
    onMounted(() => {
      if (isNewClient) {
        planesTable.dialog = true;
      }
    });

    let viewPlanCliente = vm.proxy.$root.$route.query.viewPlanCliente; // query desde el apartado de reporte de vigencia de contrato (al consultar uno)
    let itemFromContratoVigenciaView = viewPlanCliente ? reactive(btoaDecodeData(vm.proxy.$root.$route.query.q)) : {};
    function showPlanClienteOnEnter(item) {
      const showDialog = true;
      planesTable.setEditedItem(item, showDialog);
    }

    onUpdated(() => {
      if (viewPlanCliente && state[PLANES_CLIENTE].length && !tableLoading.value) {
        viewPlanCliente = false;
        vm.proxy.$root.$router.replace(`planes`);
        vm.proxy.$refs.edit_item.click();
      }
    });

    const headers = [
      { text: "No. póliza", value: "poliza", align: "center", sortable: true },
      { text: "Nombre del plan", value: "plan_catalog_data.nombre" },
      { text: "Moneda", value: "moneda" },
      { text: "Forma de pago", value: "forma_pago" },
      // { text: "Fecha inicio plan", value: "fecha_inicial" },
      { text: "Prox. pago", value: "fecha_prox_pago" },
      { text: "Saldo a favor", value: "saldo_a_favor" },
      { text: "Estatus", value: "estatus" },
      { text: "Acciones", value: "action" },
    ];

    // Los campos con VCurrencyField necesitan inicializarse o marcaran error
    const defaultValuesOnOpen = {
      anios_aseguradora: "",
      aseguradora_total: "",
      trimestres_pagados: 0,
      recibido_a_cuenta: 0,
      abonos_pagados_migracion: 0,
      tipo_cambio: "",
      prima_neta: "",
      pago_fraccionado: "",
      gastos_expedicion: "",
      dia_pago: "",
      num_dia_pago: 0,
      saldo_a_favor: "",
      saldo_en_contra: "",
      pago_periodico_anio: 0,
      plan_catalog_data: {},
    };

    const clienteId = Number(vm.proxy.$root.$route.params.idCliente);
    
    const fullPageLoading = ref(false);
    const vueElementLoadingText = ref("Procesando...");

    const logged_user = getters[USERS].findById(state[APP].idUser);
    actions[PLANES_CLIENTE].FETCH(clienteId);
    const tableLoading = getters[APP].getLoading;
    const dataSource = getters[PLANES_CLIENTE].getAll;
    const cliente = getters[CLIENTES].findById(clienteId);
    const planSelected = ref({});

    const planesTable = useDataTable(dataSource, defaultValuesOnOpen);
    const planes = computed(() => (planesTable.isEditable ? getters[PLANES].getAll.value : getters[PLANES].getActivos.value));
    let planesSelect = computed(() => {
      if (planesTable.isEditable) {
        return [planSelected.value].map(plan => {
          const existePlan = planes.value.find(item => item.id == plan.id);
          const estatus    = !existePlan ? '(Eliminado)' : existePlan.estatus ? '' : '(Dado de baja)';
          
          return { text: `${plan.nombre} ${estatus}`, value: plan.id };
        });
      }

      return planes.value.map(plan => ({ text: `${plan.nombre} ${plan.estatus ? '' : '(Dado de baja)'}`, value: plan.id }));
    });

    const FORMA_PAGO_SELECT = computed(() => {
      if (planesTable.isEditable) {
        if (!esFormaPagoNoTrimestral(planesTable.editedItem.forma_pago)) {
          return PLAN_DATA.FORMA_PAGO_SELECT.slice(0, 5);
        }
        else if (planesTable.editedItem.forma_pago == FORMA_PAGO.SEMESTRAL) {
          return PLAN_DATA.FORMA_PAGO_SELECT.slice(5, 6);
        }
        else {
          return PLAN_DATA.FORMA_PAGO_SELECT.slice(6, 7);
        }
      }
      
      return PLAN_DATA.FORMA_PAGO_SELECT
    });

    const dateInit = useDatepicker(0, "");
    const dateProxPago = useDatepicker(0, "");
    const dateInitContrato = useDatepicker(0, "");
    const dateEndContrato = useDatepicker(0, "");

    // const fechaProxPago = computed(() => 
    //   dateProxPago(
    //     dateInit.dateText, 
    //     planesTable.editedItem.forma_pago, 
    //     planesTable.editedItem.dia_pago,
    //     true,
    //   )
    // );

    /* Valores iniciales al abrir el modal del plan de cliente en modo de edicion,
    principalmente para deshabilitar o habilitar campos */
    const valorInicialEstatusSuspension = ref(false);
    const valorInicialEstatusBaja       = ref(false);

    const showSuspendDeactivationDialog = ref(false);
    const nuevoTipoCambioOnDesSuspension = ref("0");

    const validFormPlanes = ref(true);
    const validFormSuspension = ref(true);
    const validFormTrimestresPagados = ref(true);
    const trimestresFields    = ref([]);
    const trimestresFieldsAll = ref([]);
    // Copia del original para cuando se cancela en el modal editable se pueda reestablecer
    const trimestresFieldsCopy = ref([]);
    const trimestresDialog = ref(false);
    const tipo_cambio_migr = ref("0");
    const tipo_cambio_migr_copy = ref("0");

    // Resetear validacion cuando se abre el dialog/modal e inicializar/resetear otros datos
    watch(() => planesTable.dialog, (dialog) => {
      if (dialog) {
        planesTable.editedItem.saldo_a_favor = Math.abs(planesTable.editedItem.saldo_a_favor);
        planesTable.editedItem.saldo_en_contra = +planesTable.editedItem.saldo_en_contra;
        trimestres.value     = +planesTable.editedItem.trimestres_pagados;
        abonos_pagados.value = +planesTable.editedItem.abonos_pagados_migracion;

        trimestres_copy.value      = trimestres.value;
        abonos_pagados_copy.value  = abonos_pagados.value;
        planesTable.editedItem.recibido_a_cuenta_copy = planesTable.editedItem.recibido_a_cuenta;
        planesTable.editedItem.saldo_a_favor_copy     = planesTable.editedItem.saldo_a_favor;
        planesTable.editedItem.saldo_en_contra_copy    = planesTable.editedItem.saldo_en_contra;
        
        if (planesTable.isEditable) { // Si el modal ESTÁ en modo editable...
          // Mostrar la moneda USD sólo con 4 decimales
          if (planesTable.editedItem.moneda == MONEDAS.USD) {
            planesTable.editedItem.tipo_cambio = planesTable.editedItem.tipo_cambio.slice(0, -2);
          }

          valorInicialEstatusSuspension.value = planesTable.editedItem.suspendido;
          valorInicialEstatusBaja.value       = planesTable.editedItem.estatus;

          planSelected.value = {...planesTable.editedItem.plan_catalog_data};

          dateInit.dateText         = planesTable.editedItem.fecha_inicial;
          dateProxPago.dateText     = planesTable.editedItem.fecha_prox_pago || null;
          dateInitContrato.dateText = planesTable.editedItem.fecha_inicial_contrato || null;
          dateEndContrato.dateText  = planesTable.editedItem.fecha_fin_contrato || null;

          // Obtener los trimestres pagados del plan de pagos
          // setTimeout(() => {
            getTrimestresPagadosPlanPago(planesTable);
          // }, 300)
        }
        else { // Si el modal NO está en modo editable...
          dateInit.dateText     = vm.proxy.$root.$dayjs().format('YYYY-MM-DD');
          dateProxPago.dateText = vm.proxy.$root.$dayjs().format('YYYY-MM-DD');
        } 
        
        if (vm.proxy.$refs.form)
          vm.proxy.$refs.form.resetValidation();
      }
      else { // Si el modal se cierra, resetear las sig. propiedades
        dateInit.dateText = null;
        dateInitContrato.dateText = null;
        dateEndContrato.dateText = null;
        planSelected.value = {};

        trimestres.value = 0;
        trimestres_copy.value = 0;
        planesTable.editedItem.recibido_a_cuenta_copy = 0;
        planesTable.editedItem.saldo_a_favor_copy = 0;
        planesTable.editedItem.saldo_en_contra_copy = 0;
        abonos_pagados.value = 0;
        abonos_pagados_copy.value = 0;
        tipo_cambio_migr.value      = "0";
        tipo_cambio_migr_copy.value = "0";
        trimestresFieldsAll.value = [];
        trimestresFields.value = [];
        trimestresFieldsCopy.value = [];

        valorInicialEstatusSuspension.value = false;
        valorInicialEstatusBaja.value       = false;

        APP_DEBUG_MODE && console.log("[PlanesClientes]: PlanPagos onCloseModal => SET_DEFAULT()");
        actions[PLAN_PAGOS].SET_DEFAULT();
      }
    });

    // Obtener los trimestres pagados del plan de pagos
    function getTrimestresPagadosPlanPago(planClienteTable) {
      const fetchWithAbonos = false;
      actions[PLAN_PAGOS].FETCH(planClienteTable.editedItem.id, planClienteTable.editedItem.suspendido, (result, responseObj) => {
        if (isObj(responseObj) && !responseObj.success) {
          planClienteTable.close();
        }

        trimestresFieldsAll.value  = [...result].sort(sortById).map(trimestre => {
          // Mostrar la moneda USD sólo con 4 decimales
          if (trimestre.moneda == MONEDAS.USD) {
            trimestre.tipo_cambio = trimestre.tipo_cambio.slice(0, -2);
          }
          // Mostrar la moneda MXN sin decimales
          else if (trimestre.moneda == MONEDAS.MXN) {
            trimestre.tipo_cambio = trimestre.tipo_cambio.split(".")[0];
          }

          return trimestre;
        });
        trimestresFields.value     = trimestresFieldsAll.value.slice(0, +planClienteTable.editedItem.trimestres_pagados + 1);
        trimestresFieldsCopy.value = trimestresFields.value.map(obj => Object.assign({}, obj));

        const trimestre_actual      = trimestresFields.value.length ? [...trimestresFields.value].pop() : {};
        tipo_cambio_migr.value      = trimestre_actual.tipo_cambio;
        tipo_cambio_migr_copy.value = tipo_cambio_migr.value;
      }, fetchWithAbonos);
    }

    // Si la moneda se pone en MN, se asigna el tipo de cambio a 1, sino lo cambia a "" y se tiene que especificar
    watch(() => planesTable.editedItem.moneda, (moneda, prevMoneda) => {
      if (moneda) {
        planesTable.editedItem.tipo_cambio = 
          (moneda == MONEDAS.MXN ) 
            ? 1 
            : prevMoneda
            ? "" 
            : planesTable.editedItem.tipo_cambio;
      }
    });

    watch(() => planesTable.editedItem.forma_pago, (value, oldValue) => {
      if (value == FORMA_PAGO.SEMANAL && !planesTable.editedItem.dia_pago) {
        planesTable.editedItem.dia_pago = normalizeStr(vm.proxy.$root.$dayjs(dateInit.dateText).format("dddd"));
      }

      /* Si se cambia la forma de pago en el modo editable, recalcular el pago periodico, manteniendo
      el calculo original como referencia (plazo_calculado.value) si es que se modifica "pago_periodico_anio" */
      if (planesTable.isEditable && oldValue && oldValue != value) {
        planesTable.editedItem.pago_periodico_anio = plazo_calculado.value;
        vm.proxy.$refs.forma_pago.blur();

        if (planesTable.editedItem.forma_pago == FORMA_PAGO.SEMANAL) {
          vm.proxy.$refs.dia_pago.focus();
        }
        vm.proxy.$refs.pago_periodico.focus();
      }

      /* Si se cambia la forma de pago en el modo NO editable (registro nuevo), calcular el pago periodico, manteniendo
      el calculo original como referencia (plazo_calculado.value) si es que se modifica "pago_periodico_anio" */
      if (!planesTable.isEditable && value) {
        planesTable.editedItem.pago_periodico_anio = plazo_calculado.value;
        vm.proxy.$refs.forma_pago.blur();

        if (planesTable.editedItem.forma_pago == FORMA_PAGO.SEMANAL) {
          vm.proxy.$refs.dia_pago.focus();
        }
        vm.proxy.$refs.pago_periodico.focus();
      }
    });

    watch(() => dateInit.dateText, (value) => {
      if (planesTable.editedItem.forma_pago == FORMA_PAGO.SEMANAL && !planesTable.isEditable) {
        planesTable.editedItem.dia_pago = normalizeStr(vm.proxy.$root.$dayjs(dateInit.dateText).format("dddd"));
      }
    });

    watch(() => +planesTable.editedItem.recibido_a_cuenta == 0, (value) => {
      if (value) {
        abonos_pagados.value = 0;
      }
    });

    // REVIEW: Si se entra a la ventana de plan de cliente y se desactiva el estatus suspendido
    watch(() => planesTable.editedItem.suspendido, (value, oldValue) => {
      const dia_actual_mayor_a_dia_pago = vm.proxy.$root.$dayjs().isAfter(vm.proxy.$root.$dayjs(dateProxPago.dateText), "day");

      if (!value && oldValue && dia_actual_mayor_a_dia_pago && valorInicialEstatusSuspension.value && planesTable.isEditable) {
        /* Mostrar modal para avisar que se va a actualizar el plan de clientes los abonos y el plan de pagos conforme al dia actual */
        showSuspendDeactivationDialog.value = true;

        /* Chequeo de tipo de cambio, si la moneda del plan de cliente es en MXN, el tipo de cambio será forzado en 1.
        El input de texto tiene esta misma validación para que no sea visible si se cumple */
        if (planesTable.editedItem.moneda && planesTable.editedItem.moneda == MONEDAS.MXN) {
          nuevoTipoCambioOnDesSuspension.value = "1";
        }
        
        setTimeout(() => {
          vm.proxy.$refs.form_suspension.resetValidation();
        }, 100);
      }
    });
    
    /* Revertir el estatus de suspensión, cerrar el modal al tratar de cerrarlo en el boton "X" o al hacer clic fuera
    y poner el tipo de cambio a su valor por default */
    function handleOnCloseSuspendDeactivationDialog() {
      planesTable.editedItem.suspendido = true;
      showSuspendDeactivationDialog.value = false;
      nuevoTipoCambioOnDesSuspension.value = "0";
    }

    const tooltip_trimestres_al_anio = computed(() => {
      return '';

      // const trimestres_anio = 4;
      // return `${planesTable.editedItem.anios_aseguradora * trimestres_anio} trimestres`;
    });

    // Total en 1 anio
    const asegTotal = computed(() => {
      // const total = currency(
      //   (+planesTable.editedItem.prima_neta 
      //   + +planesTable.editedItem.pago_fraccionado)
      //   + +planesTable.editedItem.gastos_expedicion
      // );
      const total = currency(
        (+planesTable.editedItem.prima_neta + +planesTable.editedItem.pago_fraccionado)
      );

      return total;
    });

    // Equivalencia en 1 trimestre (1 año, MN)
    const calcular_trimestre = computed(() => {
      const trimestres_anio = 4;
      return currency(
        (asegTotal.value
        * planesTable.editedItem.tipo_cambio)
        / trimestres_anio
      );
    });

    // Calcula el plazo (diario, semanal, mensual, ...) en 1 trimestre (1 año, MN)
    const plazo_calculado = computed(() => {
      const primer_anio = 1;
      const pago_periodico = calcularPlazo(
        primer_anio,
        asegTotal.value,
        planesTable.editedItem.forma_pago,
        planesTable.editedItem.tipo_cambio,
        dateInit.dateText,
        false,
      );

      return pago_periodico;
    });

    // Total anios restantes
    const asegTotalResto = computed(() => {
      const anios = +planesTable.editedItem.anios_aseguradora - 1;
      // let total = currency((asegTotal.value - +planesTable.editedItem.gastos_expedicion) * anios);
      let total = currency((asegTotal.value) * anios);

      total = anios > 0 ? total : 0;
      return total;
    });

    // Equivalencia en 1 trimestre (años restantes, MN)
    const calcular_trimestre_resto = computed(() => {
      const trimestres_anio = 4;
      const trimestres_resto = (+planesTable.editedItem.anios_aseguradora * trimestres_anio) - 4;
      return currency(
        (asegTotalResto.value
        * planesTable.editedItem.tipo_cambio)
        / trimestres_resto
      );
    });

    // Calcula el plazo (diario, semanal, mensual, ...) en 1 trimestre (años restantes, MN)
    // const plazo_calculado_resto = computed(() => {
    //   const anios_restantes = +planesTable.editedItem.anios_aseguradora - 1;
    //   const restantes = true;
    //   const pago_periodico_resto = calcularPlazo(
    //     anios_restantes,
    //     asegTotalResto.value,
    //     planesTable.editedItem.forma_pago,
    //     planesTable.editedItem.tipo_cambio,
    //     dateInit.dateText,
    //     true,
    //   );

    //   return pago_periodico_resto;
    // });

    function handleChangePlan(value) {
      planesTable.editedItem.id_plan = value;
      planSelected.value = planes.value.find(plan => plan.id == value);
      planesTable.editedItem.anios_aseguradora = planSelected.value.min_anios || 1;
    }

    function goToClientePlanPago() {
      vm.proxy.$root.$router.push({
        path: `/consulta_plan_pagos`,
        query: { 
          qClienteId: btoaEncodeData(cliente.value.id), 
          qPlanClienteId: btoaEncodeData(planesTable.editedItem.id) 
        }
      });
    }

/** ============================ */
/** INICIA APARTADO DE MIGRACION */
/** ============================ */
    // :value trimestres pagados
    const trimestres = ref(0);
    // Copia del original para cuando se cancela en el modal editable se pueda reestablecer
    const trimestres_copy = ref(0);

    // @input trimestres pagados
    function handleInputTrimestres(value) {
      const val = value;
      trimestres.value = val;
      // https://vuejs.org/v2/api/#Vue-nextTick
      // Se ejecutará éste callback "nextTick" cuando se haya terminado de actualizar el DOM
      vm.proxy.$root.$nextTick(() => {
        trimestres.value = isNaN(+planesTable.editedItem.trimestres_pagados) ? "0" : planesTable.editedItem.trimestres_pagados+"";
      });

      if(!["Semestral", "Anual"].includes(planesTable.editedItem.forma_pago) && (val / 4) >= +planesTable.editedItem.anios_aseguradora) {
        planesTable.editedItem.trimestres_pagados = (+planesTable.editedItem.anios_aseguradora * 4) - 1;
      }
      else if (["Semestral"].includes(planesTable.editedItem.forma_pago) && (val / 2) >= +planesTable.editedItem.anios_aseguradora) {
        planesTable.editedItem.trimestres_pagados = (+planesTable.editedItem.anios_aseguradora * 2) - 1;
      }
      else if (["Anual"].includes(planesTable.editedItem.forma_pago) && val >= +planesTable.editedItem.anios_aseguradora) {
        planesTable.editedItem.trimestres_pagados = (+planesTable.editedItem.anios_aseguradora) - 1;
      }
      else {
        planesTable.editedItem.trimestres_pagados = +val;
      }

      // Crear los campos de texto para establecer los abonos y saldos pendientes
      if (trimestresFields.value.length > 1 && +planesTable.editedItem.trimestres_pagados > 0) {
        trimestresFields.value = trimestresFields.value.slice(0, +planesTable.editedItem.trimestres_pagados + 1);
      }
      else {
        trimestresFields.value = [];
      }
      
      for (let index = trimestresFields.value.length; index < (+planesTable.editedItem.trimestres_pagados + 1); index++) {
        const trimestre_actual_data = [...trimestresFields.value].pop() || {};
        
        trimestresFields.value.push({
          ...trimestre_actual_data,
          tipo_cambio: planesTable.editedItem.tipo_cambio, 
          abonado: 0,
          saldo: 0,
        });
      }
    }

    // :value abonos pagados
    const abonos_pagados = ref(0);
    // Copia del original para cuando se cancela en el modal editable se pueda reestablecer
    const abonos_pagados_copy = ref(0);

    // @input abonos pagados
    function handleInputAbonosPagados(value) {
      const val = value;
      abonos_pagados.value = val;
      // https://vuejs.org/v2/api/#Vue-nextTick
      vm.proxy.$root.$nextTick(() => {
        abonos_pagados.value = isNaN(+planesTable.editedItem.abonos_pagados_migracion) ? "0" : planesTable.editedItem.abonos_pagados_migracion+"";
      });

      let pagos_al_trimestre_en_plan_no_iniciado = 0;
      if (!planesTable.isEditable) {
        pagos_al_trimestre_en_plan_no_iniciado = calcularNumPagosAlTrimestre(
          planesTable.editedItem.forma_pago,
          planesTable.editedItem.fecha_prox_pago
        );
      }

      const num_pagos_al_trimestre = +planesTable.editedItem.num_pagos_al_trimestre || pagos_al_trimestre_en_plan_no_iniciado;
      if (+abonos_pagados.value >= num_pagos_al_trimestre) {
        planesTable.editedItem.abonos_pagados_migracion = (num_pagos_al_trimestre - 1)
      }
      else {
        planesTable.editedItem.abonos_pagados_migracion = +val;
      }
    }

    // Los valores originales se dejan tal cual modificados, y ademas se guardan esos valores de estado actual en las propiedades "_copy"
    function handleMigracionSave() {
      trimestres_copy.value                         = trimestres.value;
      abonos_pagados_copy.value                     = abonos_pagados.value;
      tipo_cambio_migr_copy.value                   = tipo_cambio_migr.value;
      trimestresFieldsCopy.value                    = trimestresFields.value.map(obj => Object.assign({}, obj));
      planesTable.editedItem.saldo_a_favor_copy     = planesTable.editedItem.saldo_a_favor;
      planesTable.editedItem.saldo_en_contra_copy   = planesTable.editedItem.saldo_en_contra;
      planesTable.editedItem.recibido_a_cuenta_copy = planesTable.editedItem.recibido_a_cuenta;

      if (!planesTable.editedItem.plan_iniciado) {
        planesTable.editedItem.tipo_cambio = tipo_cambio_migr_copy.value;
      }

      trimestresDialog.value = false;
      vm.proxy.$root.$toast.success(`Abonos por ${plazoToString(planesTable.editedItem.forma_pago).lower} guardados`);
    }

    // Si al estarse editando los trimestres y abonos se decide cancelar, se devuelve el último estado (inicial o el ultimo guardado) con las propiedades "_copy"
    function handleMigracionCancel() {
      trimestres.value                         = trimestres_copy.value;
      abonos_pagados.value                     = abonos_pagados_copy.value;
      tipo_cambio_migr.value                   = tipo_cambio_migr_copy.value;
      trimestresFields.value                   = trimestresFieldsCopy.value.map(obj => Object.assign({}, obj));
      planesTable.editedItem.saldo_a_favor     = planesTable.editedItem.saldo_a_favor_copy;
      planesTable.editedItem.saldo_en_contra   = planesTable.editedItem.saldo_en_contra_copy;
      planesTable.editedItem.recibido_a_cuenta = planesTable.editedItem.recibido_a_cuenta_copy;
      
      trimestresDialog.value = false;
    }

    watch(() => trimestresDialog.value, (value, oldValue) => {
      // Solo se ejecutara en la creacion de una poliza nueva
      if (value && !planesTable.editedItem.plan_iniciado) {
        tipo_cambio_migr.value      = planesTable.editedItem.tipo_cambio;
        tipo_cambio_migr_copy.value = tipo_cambio_migr.value;
      }
    });

    function handleTipoCambio(tipo_cambio, idx, pago_en_mxn_al_trimestre) {
      const index = +idx;
      trimestresFields.value[index].tipo_cambio = tipo_cambio;

      let saldo = (+pago_en_mxn_al_trimestre - +trimestresFields.value[index].abonado);
      if (index > 0) {
        // Sumar el saldo anterior (ya sea a favor o en contra)
        saldo += +trimestresFields.value[index - 1].saldo;
      }

      trimestresFields.value[index].saldo = currency(saldo);
      
      // Actualizar los saldos posteriores
      for (let i = (index + 1); i < trimestresFields.value.length; i++) {
        const saldo = ((+trimestresFields.value[i].tipo_cambio * pagoMonedaContratada(i)) - +trimestresFields.value[i].abonado) + +trimestresFields.value[i-1].saldo;
        trimestresFields.value[i].saldo = currency(saldo);
      }
    }

    function handleInputTipoCambioMigr(tipo_cambio_value) {
      tipo_cambio_migr.value = tipo_cambio_value;
    }

    // Fechas permitidas para el campo de fecha inicial
    function allowedDates(val) {
      const date = val;
      
      return vm.proxy.$root.$dayjs(date).format('dddd') != DIAS_SEMANA.DOM;
    }

    function handleAbonado(abonado, index, pago_en_mxn_al_trimestre) {
      trimestresFields.value[index].abonado = abonado;

      let saldo = pago_en_mxn_al_trimestre - +abonado;
      if (index > 0) {
        // Sumar el saldo anterior (ya sea a favor o en contra)
        saldo += +trimestresFields.value[index - 1].saldo;
      }

      trimestresFields.value[index].saldo = currency(saldo);

      // Actualizar los saldos posteriores
      for (let i = (index + 1); i < trimestresFields.value.length; i++) {
        const saldo = ((+trimestresFields.value[i].tipo_cambio * pagoMonedaContratada(i)) - +trimestresFields.value[i].abonado) + +trimestresFields.value[i-1].saldo;
        trimestresFields.value[i].saldo = currency(saldo);
      }
    }

    function pagoMonedaContratada(idx) {
      const index = idx + 1;
      let result = 0;

      if (index <= 1) {
        result = planesTable.editedItem.moneda == MONEDAS.MXN 
          ? +calcular_trimestre.value + +planesTable.editedItem.gastos_expedicion
          : (+calcular_trimestre.value / +planesTable.editedItem.tipo_cambio) + +planesTable.editedItem.gastos_expedicion;
      }
      else {
        result = planesTable.editedItem.moneda == MONEDAS.MXN 
          ? +calcular_trimestre.value 
          : (+calcular_trimestre.value / +planesTable.editedItem.tipo_cambio);
      }

      return currency(result);
    }
/** ============================= */
/** TERMINA APARTADO DE MIGRACION */
/** ============================= */


/** ================================ */
// GUARDAR/ACTUALIZAR PLAN DE CLIENTE
/** ================================ */
    function planClienteSave() {
      activateFullPageLoading(vueElementLoadingText, fullPageLoading);

      planesTable.save(isEditable => {
        setPlanClienteDataBeforeSave(planesTable);

        // Actualizar o insertar plan
        const data = {...planesTable.editedItem};
        APP_DEBUG_MODE && console.log("Datos planes_cliente a enviar: ", data);

        const actionMethod = isEditable ? "UPDATE" : "INSERT";
        actions[PLANES_CLIENTE][actionMethod](data, (success) => {

          if (success) {
            // Evitar el popup del dialog si se accede a los planes de un cliente y no se ha registrado ninguno
            if (!planesTable.items.length && isNewClient) {
              vm.proxy.$root.$router.replace(`planes`);
            }
            planesTable.close();
          }

          disableFullPageLoading(fullPageLoading);
        });
      });
    }

/** =============================================== */
// ACTUALIZAR PLAN DE CLIENTE AL QUITAR SUSPENSION
/** =============================================== */
    // REVIEW: Esta funcion se encarga de actualizar el plan de cliente, plan de pago, e insertar abonos al quitar la suspension
    function planClienteUpdateOnSuspendDeactivation() {
      planesTable.save(isEditable => {
        activateFullPageLoading(vueElementLoadingText, fullPageLoading);
        setPlanClienteDataBeforeSave(planesTable);
        
        const data = {
          fecha_actual:          vm.proxy.$root.$dayjs().format("YYYY-MM-DD"),
          nuevo_tipo_cambio:     +nuevoTipoCambioOnDesSuspension.value,
          plan_cliente:          {...planesTable.editedItem},
          trimestres_fields_all: [...trimestresFieldsAll.value],
          trimestre_actual:      [...trimestresFields.value].pop(),
          cliente:               cliente.value,
          user:                  logged_user.value
        };

        delete data.plan_cliente.recibido_a_cuenta_copy;
        delete data.plan_cliente.saldo_a_favor_copy;
        delete data.plan_cliente.saldo_en_contra_copy;
        delete data.plan_cliente.trimestres_fields;
        delete data.plan_cliente.tipo_cambio_trimestre_actual_migr;

        APP_DEBUG_MODE && console.log("Datos planes_cliente a enviar antes de la des-suspensión: ", data);

        const actionMethod = "UPDATE_ON_SUSPEND_DEACTIVATION";
        actions[PLANES_CLIENTE][actionMethod](data, (success) => {

          if (success) {
            showSuspendDeactivationDialog.value = false;
            valorInicialEstatusSuspension.value = false; // Se pone en false para que puedan editar el plan del cliente despues de este proceso si así lo desean
            nuevoTipoCambioOnDesSuspension.value = "0";
            planesTable.close();
          }

          setTimeout(() => {
            disableFullPageLoading(fullPageLoading);
          }, 500);
        });
      });
    }

/** ======================== */
// ELIMINAR PLAN DE CLIENTE
/** ======================== */
    function planClienteDelete(planClienteData) {
      actions[PLANES_CLIENTE].DELETE(planClienteData, () => planesTable.close());
    }

/** ======================================= */
// SETEAR LOS VALORES DINAMICOS DEL FORM
// ANTES DE ENVIARLO A INSERTAR/ACTUALIZAR
/** ======================================= */
    function setPlanClienteDataBeforeSave(planClienteData) {
      planClienteData.editedItem.id_cliente = clienteId;
      planClienteData.editedItem.aseguradora_total = asegTotal.value + +planClienteData.editedItem.gastos_expedicion + asegTotalResto.value;
      planClienteData.editedItem.plan_catalog_data = {
        ...planSelected.value,
      };

      planClienteData.editedItem.trimestres_pagados = trimestres.value;
      // Recibido a cuenta y saldo a favor ya se actualizas solos, por lo que no necesitan asignarse en este apartado
      planClienteData.editedItem.abonos_pagados_migracion = abonos_pagados.value;
      planClienteData.editedItem.tipo_cambio_trimestre_actual_migr = tipo_cambio_migr.value;
      planClienteData.editedItem.trimestres_fields = trimestresFields.value;

      planClienteData.editedItem.pago_periodico_resto = +planClienteData.editedItem.pago_periodico_anio;
      planClienteData.editedItem.fecha_inicial = dateInit.dateText;

      if (+planClienteData.editedItem.saldo_a_favor > 0) {
        planClienteData.editedItem.saldo_a_favor = +planClienteData.editedItem.saldo_a_favor * -1;
      }
      
      // Se entra al registrar un plan de cliente, y al editar si el plan no ha sido iniciado
      if (!planClienteData.editedItem.plan_iniciado) {
        planClienteData.editedItem.fecha_prox_pago = vm.proxy.$root.$dayjs(planClienteData.editedItem.fecha_inicial).format('YYYY-MM-DD');
        planClienteData.editedItem.fecha_prox_pago_tolerancia = planClienteData.editedItem.fecha_prox_pago;
        planClienteData.editedItem.forma_pago_inicial = planClienteData.editedItem.forma_pago;
        planClienteData.editedItem.tipo_cambio_trimestre_actual_migr = planClienteData.editedItem.tipo_cambio;

        planClienteData.editedItem.num_pagos_al_trimestre = calcularNumPagosAlTrimestre(
          planClienteData.editedItem.forma_pago,
          planClienteData.editedItem.fecha_prox_pago,
        );
        // planClienteData.editedItem.abonos_pagados_migracion = abonos_pagados.value;
      }
      else {
        planClienteData.editedItem.fecha_prox_pago = dateProxPago.dateText;
        planClienteData.editedItem.fecha_prox_pago_tolerancia = planClienteData.editedItem.fecha_prox_pago;
        
          /* Si el trimestre actual tiene abonos activos y la forma de pago del trimestre actual es diferente a la del plan de cliente,
          quiere decir que se ha cambiado la forma de pago y entonces se tiene que recalcular el numero de pagos al trimestre para que
          cuadre con la nueva forma de pago establecida */
          // const trimestre_actual = [...trimestresFields.value].pop();

          // if (trimestre_actual.abonos_activos.length && trimestre_actual.forma_pago != planClienteData.editedItem.forma_pago) {
          //   planClienteData.editedItem.num_pagos_al_trimestre = calcularNumPagosAlTrimestre(
          //     planClienteData.editedItem.forma_pago,
          //     trimestre_actual.abonos_activos[0].fecha_abono,
          //   );
          // }
      }

      if (dateInitContrato.dateText && dateEndContrato.dateText) {
        planClienteData.editedItem.fecha_inicial_contrato = dateInitContrato.dateText;
        planClienteData.editedItem.fecha_fin_contrato = dateEndContrato.dateText;
      }

      if (planClienteData.editedItem.forma_pago != FORMA_PAGO.SEMANAL) {
        planClienteData.editedItem.dia_pago = '';
      }

      // Forzar el tipo de cambio a 1 cuando la moneda es en MN
      if (planClienteData.editedItem.moneda == MONEDAS.MXN) {
        planClienteData.editedItem.tipo_cambio = 1;
        planClienteData.editedItem.tipo_cambio_trimestre_actual_migr = 1;
      }
    }

    return {
      /** IMPORTS */
      plazoToString,
      currency,
      formatCurrency,
      tipoCambioMaxLength,
      truncateStr,
      formatDiaPago,
      PLAN_DATA,
      FORMA_PAGO,
      FORMA_PAGO_SELECT,
      MONEDAS,
      /** IMPORTS */

      itemFromContratoVigenciaView,
      showPlanClienteOnEnter,

      fullPageLoading,
      vueElementLoadingText,

      cliente,
      tooltip_trimestres_al_anio,
      handleChangePlan,
      goToClientePlanPago,
      planSelected,
      headers,
      planesSelect,
      planesTable,
      planClienteSave,
      planClienteDelete,
      validFormPlanes,
      validFormSuspension,
      validFormTrimestresPagados,
      tableLoading,
      asegTotal,
      asegTotalResto,
      calcular_trimestre,
      plazo_calculado,
      calcular_trimestre_resto,
      // plazo_calculado_resto,
      dateInit,
      dateInitContrato,
      dateEndContrato,
      allowedDates,
      dateProxPago,
      valorInicialEstatusSuspension,
      valorInicialEstatusBaja,

      /** MANEJAR ESTATUS DE DES-SUSPENSION */
      showSuspendDeactivationDialog,
      handleOnCloseSuspendDeactivationDialog,
      planClienteUpdateOnSuspendDeactivation,
      nuevoTipoCambioOnDesSuspension,
      /** MANEJAR ESTATUS DE DES-SUSPENSION */

      /** APARTADO DE MIGRACION */
      trimestres,
      handleInputTrimestres,
      abonos_pagados, 
      tipo_cambio_migr,
      handleInputAbonosPagados,
      trimestresFields,
      trimestresFieldsAll,
      trimestresDialog,
      handleTipoCambio,
      handleAbonado,
      handleMigracionSave,
      handleMigracionCancel,
      handleInputTipoCambioMigr,
      pagoMonedaContratada,
      /** APARTADO DE MIGRACION */

      IS_DEV_USER,

      // Funcs. de validacion
      FormValidations,
    };
  }
};
</script>

<style lang="scss" scoped>

// #plancliente-table {
//   tbody tr {
//     cursor: pointer;
//   }
// }

#linkReturnClientes:hover { 
  text-decoration: underline;
}

</style>